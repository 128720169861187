import React, { useRef } from 'react';
import { atom, useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { HiOutlineMicrophone } from "react-icons/hi";
import { MdSend } from "react-icons/md";
import { isMobile } from 'react-device-detect';

import sendIcon from '../../../../../assets/images/icons/send.svg';

const inputAtom = atom('');

const ChatInput = ({ pendingMessages, setPendingMessages, setAwaitAI, handleSendMessage }) => {
    const textareaRef = useRef(null);

    const [input, setInput] = useAtom(inputAtom);
    const { t } = useTranslation();

    const sendMessage = () => {
        handleSendMessage(input);
        setInput('');  // Clear the input field
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
        }
    };

    return (
        <div className="px-6 pb-6 pt-3 mt-3 border-t border-neutral-700">
            <div className="flex items-center">
                <div className="flex grow items-center bg-neutral-900 rounded-lg ring-1 ring-neutral-600 focus-within:ring-2 focus-within:ring-primary-500">
                    <textarea
                        ref={textareaRef}
                        placeholder={t('chat-placeholder')}
                        className="flex-1 bg-neutral-900 text-white px-4 py-3 rounded-lg resize-none overflow-y-auto max-h-40 border-none focus:outline-none focus:ring-0 focus:border-0 focus:shadow-none"
                        style={{ whiteSpace: 'normal' }}
                        value={input}
                        onChange={(e) => {
                            setInput(e.target.value);
                            e.target.style.height = 'auto';
                            e.target.style.height = e.target.scrollHeight + 'px';
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && !e.shiftKey && !isMobile) {
                                e.preventDefault();
                                sendMessage();
                            }
                        }}
                        rows={1}
                    />
                </div>
                <div className="ml-2 p-2 cursor-pointer text-white">
                    <img src={sendIcon} alt="send" onClick={sendMessage} />
                </div>
            </div>
        </div>
    );
}

export default ChatInput;
