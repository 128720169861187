
import React, { useState, useContext, useEffect } from 'react';
import { IoChatbubbleEllipsesOutline, IoClose } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';

import '../../lang/i18n';
import { useTranslation } from 'react-i18next';
import { TestSide } from '../../components/TestSide';
import { AuthContext } from '../../contexts/AuthContext';
import { NavigationContext } from '../../contexts/NavigationContext';

import chatBubbleIcon from '../../../assets/images/icons/chatBubble.svg';
import taskIcon from '../../../assets/images/icons/task.svg';
import developmentPlanIcon from '../../../assets/images/icons/developmentPlan.svg';
import accountIcon from '../../../assets/images/icons/account.svg';
import supportIcon from '../../../assets/images/icons/support.svg';
import aboutCompanyIcon from '../../../assets/images/icons/about.svg';
import englishFlag from '../../../assets/images/icons/english.svg';
import swedishFlag from '../../../assets/images/icons/swedish.svg';
import logoutIcon from '../../../assets/images/icons/logout.svg';
import zebrainIcon from '../../../assets/images/icons/zebrain.svg';
import chevronLeftIcon from '../../../assets/images/icons/chevronLeft.svg';
import menuIcon from '../../../assets/images/icons/menu.svg';

const Navbar = () => {
    const [language, setLanguage] = useState('en');

    const translation = useTranslation();
    const { t, i18n } = translation;
    const navigate = useNavigate();
    const {
        login,
        logout
    } = useContext(AuthContext);
    const { showNavigation } = useContext(NavigationContext);


    const getLanguage = () => {
        // remove -GB from en-GB
        return i18n.language.split('-')[0];
    }

    const handleLanguageChange = () => {

        const newLang = getLanguage() === 'en' ? 'sv' : 'en';
        console.log("Changing language to:", newLang)
        console.log("Current language:", getLanguage())
        i18n.changeLanguage(newLang).then(() => {
            // Save the selected language to localStorage
            localStorage.setItem('language', newLang);
        });
    };

    // get current url for nav titles
    const currentUrl = window.location.pathname.split('/')[1];

    const navTitles = {
        "chat": t("chat-nav-title"),
        "focus-area": t("focus-area-nav-title"),
    }


    // Chat, my tasks, my development plan, my account, support, About Company, 
    const menuItems = [
        {
            id: 1,
            title: t('menu-chat'),
            onClick: () => navigate('/'),
            icon: <img src={chatBubbleIcon} alt="chat" />
        },
        {
            id: 2,
            title: t('menu-my-tasks'),
            onClick: () => navigate('/tasks'),
            icon: <img src={taskIcon} alt="tasks" />
        },
        {
            id: 3,
            title: t('menu-development-plan'),
            onClick: () => navigate('/development-plan'),
            icon: <img src={developmentPlanIcon} alt="development plan" />,
            divider: true
        },
        // {
        //     id: 4,
        //     title: t('menu-my-account'),
        //     link: '/account',
        //     icon: <img src={accountIcon} alt="account" />,
        //     divider: true
        // },
        {
            id: 5,
            title: t('menu-support'),
            onClick: () => navigate('/support'),
            icon: <img src={supportIcon} alt="support" />
        },
        {
            id: 6,
            title: t('menu-about-company'),
            onClick: () => navigate('/about'),
            icon: <img src={aboutCompanyIcon} alt="about company" />,
            divider: true
        },
        {
            id: 7,
            title: getLanguage() === 'en' ? 'SWE' : 'ENG',
            onClick: () => handleLanguageChange(),
            icon: <img src={getLanguage() === 'en' ? swedishFlag : englishFlag} alt="language" />
        }
    ]

    const generalClasses = "flex flex-col p-4 desktop:p-6 transition-all duration-300 transform fixed top-0 right-0 bottom-0 z-[60]";
    const widthPositionClasses = "max-desktop:w-full w-96"; // Width and positioning styles
    const backgroundBorderClasses = "bg-white dark:bg-neutral-800"; // Background and border styles
    const overflowScrollbarClasses = "overflow-y-auto scrollbar-thin scrollbar-thumb-rounded-full scrollbar-thumb-neutral-300 scrollbar-track-neutral-200"; // Overflow and scrollbar styles
    const darkOverflowScrollbarClasses = "dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500"; // Dark overflow and scrollbar styles
    const overlayClasses = "hs-overlay hs-overlay-open:translate-x-0 translate-x-full"; // Overlay styles
    const combinedClasses = `${generalClasses} ${widthPositionClasses} ${backgroundBorderClasses} ${overflowScrollbarClasses} ${overlayClasses} ${darkOverflowScrollbarClasses}`;

    // useEffect(() => {
    //     const browserLang = navigator.language || navigator.userLanguage;
    //     console.log("Detected browser language:", browserLang);
    //     i18n.changeLanguage(browserLang);
    // }, [i18n]);


    useEffect(() => {
        // Retrieve the language from localStorage if it exists
        const savedLang = localStorage.getItem('language') || navigator.language || navigator.userLanguage;
        i18n.changeLanguage(savedLang);
        localStorage.setItem('language', savedLang);
    }, [i18n]);

    return (
        <header className="w-full bg-white text-sm dark:bg-neutral-900 relative">
            <div className="flex  justify-between flex-nowrap w-full p-4 desktop:p-6">
                <a
                    className="flex flex-col justify-center text-xl font-semibold dark:text-white"
                    href="/"
                    aria-label="Brand"
                >
                    <img src={zebrainIcon} alt="logout" />
                </a>
                {/* {showNavigation && (
                    <button
                        type="button"
                        className="text-gray-500 hover:text-gray-600"
                        onClick={() => navigate(-1)}
                    >
                        <img src={chevronLeftIcon} alt="chevron" />
                    </button>
                )} */}
                {/* <h1 className="font-medium text-lg text-white absolute left-1/2 transform -translate-x-1/2">
                    {navTitles[currentUrl]}
                </h1> */}
                <div className="flex flex-col justify-center ml-auto">
                    <button
                        type="button"
                        className="text-gray-500 hover:text-gray-600"
                        data-hs-overlay="#docs-sidebar"
                        aria-controls="docs-sidebar"
                        aria-label="Toggle navigation"
                    >
                        <span className="sr-only">Toggle Navigation</span>
                        <img src={menuIcon} alt="menu" />
                    </button>
                </div>
                <div
                    id="docs-sidebar"
                    className={combinedClasses}
                >
                    <div className="flex justify-between items-center mb-4">
                        <a
                            className="flex-none text-xl font-semibold dark:text-white"
                            href="/"
                            aria-label="Brand"
                        >
                            <img src={zebrainIcon} alt="logout" />
                        </a>
                        <button
                            type="button"
                            className="text-white hover:text-white"
                            data-hs-overlay="#docs-sidebar"
                            aria-controls="docs-sidebar"
                            aria-label="Close navigation"
                        >
                            <span className="sr-only">Close Navigation</span>
                            <IoClose size="32px" />
                        </button>
                    </div>
                    <nav className="hs-accordion-group w-full flex flex-col justify-between flex-grow" data-hs-accordion-always-open>
                        <div className="grid gap-4 w-full">
                            {menuItems.map((item) => (
                                <div key={item.id}>
                                    <div
                                        className="flex items-center gap-x-4 p-2 text-gray-700 text-base font-medium hover:text-gray-800 dark:text-white dark:hover:text-white cursor-pointer dark:hover:text-white dark:hover:bg-neutral-700 hover:rounded-[6px]"
                                        onClick={() => item.onClick()}

                                        data-hs-overlay="#docs-sidebar"
                                        aria-controls="docs-sidebar"
                                        aria-label="Close navigation"
                                    >
                                        {item.icon}
                                        <span>{item.title}</span>
                                    </div>
                                    {item.divider && <hr className="border-neutral-600 mt-4" />}
                                </div>
                            ))}
                        </div>

                        {/* Add logout button as a footer inside the navbar */}
                        <div className="w-full">
                            <hr className="border-neutral-600 w-full mb-4" />
                            <div
                                key={'nav-logout'}
                                className="flex items-center gap-x-4 p-2 text-gray-700 font-medium text-base hover:text-gray-800 dark:text-white dark:hover:text-white cursor-pointer w-full"
                                onClick={logout}
                            >
                                <img src={logoutIcon} alt="logout" />
                                <span>{t('logout')}</span>
                            </div>
                        </div>

                        {/* <div className="p-4">
                            <button
                                type="button"
                                className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                                onClick={login}
                            >
                                Sign in
                            </button>
                        </div>
                        <div className="p-4">
                            <button
                                type="button"
                                className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                                onClick={logout}
                            >
                                Sign out
                            </button>
                        </div>
                        <div className="p-4">
                            <TestSide />
                        </div> */}
                    </nav>
                </div>
            </div>
            <hr className="border-neutral-700" />
        </header>
    );

}

export default Navbar;